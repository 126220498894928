function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function nextTick(func: (...args: unknown[]) => void) {
    setTimeout(func, 0);
}

function resolver(): [Promise<unknown>, (value?: unknown) => void] {
    let resolveReady!: (value?: unknown) => void;
    const ready = new Promise((resolve) => {
        resolveReady = resolve;
    });

    return [ready, resolveReady];
}

export { sleep, nextTick, resolver };
