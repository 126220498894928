import { resolver } from '@/helpers/async';
import prestashop from 'prestashop';

const [ready, resolveReady] = resolver();

export const RecaptchaJsAPI = {
    ready,

    get config() {
        return prestashop.modules.fractorecaptcha;
    },

    prepare(selector = 'form.recaptcha-form') {
        const forms = document.querySelectorAll<HTMLFormElement>(selector);

        forms.forEach((form) => {
            const nameRecaptcha = this.config.recaptcha_field_name;
            const toInput = form.querySelector<HTMLFormElement>(
                `[name$="${nameRecaptcha}"]`,
            );

            const submitInput = document.createElement('input');
            submitInput.type = 'hidden';
            submitInput.required = true;
            submitInput.classList.add('js-submit-name');
            submitInput.value = '1';

            form.prepend(submitInput);

            form.addEventListener('submit', async (event) => {
                event.preventDefault();
                this.load();

                await this.ready;
                const token = await grecaptcha.execute(
                    this.config.recaptcha_site_key,
                    {
                        action: 'submit',
                    },
                );

                if (!toInput) {
                    const tokenInput = document.createElement('input');
                    tokenInput.type = 'hidden';
                    tokenInput.name = nameRecaptcha;
                    tokenInput.required = true;
                    tokenInput.value = token;
                    form.prepend(tokenInput);
                } else {
                    toInput.value = token;
                }

                const submitter = event.submitter as HTMLInputElement;
                submitInput.name = submitter ? submitter.name : '';

                form.submit();
            });
        });
    },

    load() {
        if (document.querySelector('#recaptcha-js-api')) {
            return;
        }

        const script = document.createElement('script');
        script.id = 'recaptcha-js-api';
        script.src = prestashop.modules.fractorecaptcha.recaptcha_script;
        script.async = true;
        script.onload = () => grecaptcha.ready(() => resolveReady());
        document.body.append(script);
    },
};
